// 基准大小
const baseSize = 10
// 设置 rem 函数
function setRem() {
  // 当前页面宽度相对于 750 1920宽的缩放比例，可根据自己需要修改。
  const scale = document.documentElement.clientWidth / 1920;
  // 设置页面根节点字体大小
	// console.log(document.documentElement.clientWidth,'页面宽度',scale);
	// 在PC端时缩放
	if(document.documentElement.clientWidth>799){
		document.documentElement.style.fontSize = baseSize * Math.min(scale, 2.5) + 'px';
	}else{
		const baseSizes = 26;
		document.documentElement.style.fontSize = baseSizes * Math.min(scale, 1) + 'px';
	}
  
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function() {
	// console.log('999999999999999999999999999999999')
  setRem()
}