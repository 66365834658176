import Vue from 'vue'
import  VueRouter from 'vue-router'


Vue.use(VueRouter)
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch(err => err);
}


// const home = () => import("@/layout/home.vue");
const chinese = () => import("@/views/chinese/chinese.vue");
// const chinese2 = () => import("@/views2/chinese/chinese.vue");
// const chinese3 = () => import("@/views3/chinese/chinese.vue");
// const west_chinese = () => import("@/views/west_chinese/west_chinese.vue");
// const west_japanese = () => import("@/views/west_japanese/west_japanese.vue");
const chinese_02 = () => import("@/views/chinese_02/chinese_02.vue");
const chinese_04 = () => import("@/views/chinese_04/chinese_04.vue");
const chinese_05 = () => import("@/views/chinese_05/chinese_05.vue");
//
// const chinese_022 = () => import("@/views2/chinese_02/chinese_02.vue");
// const chinese_032 = () => import("@/views2/chinese_03/chinese_03.vue");
// const chinese_042 = () => import("@/views2/chinese_04/chinese_04.vue");
// const chinese_052 = () => import("@/views2/chinese_05/chinese_05.vue");
// const chinese_062 = () => import("@/views2/chinese_06/chinese_06.vue");
// const chinese_072 = () => import("@/views2/chinese_07/chinese_07.vue");
//
// const chinese_023 = () => import("@/views3/chinese_02/chinese_02.vue");
// const chinese_033 = () => import("@/views3/chinese_03/chinese_03.vue");
// const chinese_043 = () => import("@/views3/chinese_04/chinese_04.vue");
// const chinese_053 = () => import("@/views3/chinese_05/chinese_05.vue");
// const chinese_063 = () => import("@/views3/chinese_06/chinese_06.vue");
// const chinese_073 = () => import("@/views3/chinese_07/chinese_07.vue");


const routes = [
	{
		path: '/',
		name: 'chinese',
		component: chinese
	},
	{
		path: '/chinese_02',
		name: 'chinese_02',
		component: chinese_02
	},
	{
		path: '/chinese_04',
		name: 'chinese_04',
		component: chinese_04
	},
	{
		path: '/chinese_05',
		name: 'chinese_05',
		component: chinese_05
	},
]

const router = new VueRouter({
	mode: 'history',
	// mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
