import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css'
import * as Echarts from 'echarts'
import axios from "axios"
import jquery from "jquery";
Vue.prototype.$ = jquery;
Vue.prototype.$vm = new Vue();
Vue.prototype.$axios = axios;


import './config/rem.js'  //px转rem
// axios.defaults.baseURL="http://syapi.ecojapan.net"
axios.defaults.baseURL="https://api.seigenkinzoku.com"



Vue.prototype.$echarts = Echarts


Vue.use(ElementUI);
Vue.config.productionTip = false



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
